import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Image from '../components/Image'
import LogoCarousel from '../components/LogoCarousel'
import { initI18n } from '../translations'

const Home = props => {
  const { instance, lang } = props.pageContext
  const i18n = initI18n(instance, lang)
  const { edges: posts } = props.data.allMarkdownRemark

  return (
    <Layout {...props} large>
      <div className="main-content home">
        <div className="content-module references">
          <Container>
            <LogoCarousel instance={instance} />

            <div className="testimonials">
              {i18n('home.testimonials', []).map((item, i) => (
                <div key={i}>
                  <div className="quote-icon" />
                  <p>{item[0]}</p>
                  <div>
                    <strong>{item[1]}</strong>
                    <br />
                    <em>{item[2]}</em>
                    <br />
                    {item[3]}
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </div>

        <div className="dark content-module categories">
          <Container>
            <p className="lead">{i18n('home.lead')}</p>
            <p>
              Cost Partner gjennomfører kostnadsanalyser innen alle indirekte
              kostnader,
              <br />
              avdekker konkrete innsparingspotensialer, og fremforhandler nye
              leverandøravtaler.
              <br />
              Finner vi ingen besparelser betales ikke honorar. - No cure, no
              pay.
            </p>

            <h2>{i18n('home.categories')}</h2>
          </Container>
          <Image src="kategorier-no.png" alt="" />
        </div>

        <div className="content-module news">
          <Container>
            <h2>Aktuelt</h2>

            <ul>
              {posts.map(({ node: post }) => (
                <li key={post.id}>
                  <div>
                    <Link
                      to={post.fields.slug.replace(
                        /^\/?(no|se)\//,
                        `/$1/${props.pageContext.lang}/`
                      )}
                    >
                      <h4>{post.frontmatter.title}</h4>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default Home
export const pageQuery = graphql`
  query HomeNoQuery {
    allMarkdownRemark(
      limit: 6
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { country: { eq: "no" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            country
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
